<template>
    <div class="ResponsiveContentBoard">
        <img class="img" src="~@/assets/icons/outros/construction.png" />
        <label class="ResponsiveLabelMenu"> Em Construção!!! </label>
    </div>
</template>
<script>
    export default {
        data() {
            return {}
        },
    }  
</script>

<style scoped>
    .img{
    height: 120px;
    margin-left: 10px;
    }
    .ResponsiveContentBoard{
        align-items: center;
        align-content: center;
        justify-items: center;
        justify-content: center;
        flex-direction: column;
    }
</style>